@import '../../styles/base/fonts';

.app {
  height: 100vh;

  strong {
    font-family: $fontBold;
  }

  .ac-adaptiveCard {
    .ac-actionSet {
      .ac-textBlock {
        font-size: 16px;
      }
      .ac-pushButton {
        background-color: #292929;
        border: none;
        border-radius: 30px;
        color: #ffffff;
        padding: 12px 8px;
        font-weight: 300;
        transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
        cursor: pointer;

        &:hover {
          background-color: #000000;
          color: #ffffff;
        }

        &[aria-pressed='true'] {
          background-color: #ff0000;
          color: #ffffff;
        }

        &:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
      }
    }
  }
}
