@font-face {
  font-display: swap;
  font-family: $fontHeavy;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/Acciona-Heavy.woff2') format('woff2'),
    url('../../assets/fonts/Acciona-Heavy.woff2') format('woff');
}

@font-face {
  font-display: swap;
  font-family: $fontMedium;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/Acciona-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Acciona-Medium.woff2') format('woff');
}

@font-face {
  font-display: swap;
  font-family: $fontRegular;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/Acciona-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Acciona-Regular.woff2') format('woff');
}

@font-face {
  font-display: swap;
  font-family: $fontBold;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/Acciona-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Acciona-Bold.woff2') format('woff');
}
